import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import ButtonBase from "@mui/material/ButtonBase";
import imgPedidos from "./imgs/pedidos.png";
import imgCuenta from "./imgs/cuenta.png";
import imgOrdenar from "./imgs/ordenar.png";
import Chip from "@mui/material/Chip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import Fab from "@mui/material/Fab";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import EditIcon from "@mui/icons-material/Edit";
import configData from "./config.json";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const ItemPedido = styled(Paper)(({ theme }) => ({
  ...theme.typography.subtitle2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  fontSize: 13,
  fontWeight: "bold",
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
  ...theme.typography.h5,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  backgroundColor: "#18544d",
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const images = [
  {
    url: imgOrdenar,
    title: "NUEVO PEDIDO",
    width: "100%",
    id: 0,
  },
  {
    url: imgCuenta,
    title: "PEDIR CUENTA",
    width: "100%",
    id: 1,
  },
  {
    url: imgPedidos,
    title: "VER PEDIDOS",
    width: "100%",
    id: 2,
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 400,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 150,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid yellowgreen",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

function backToMesas(iAccion) {
  // iAccion = 0 : Cancelar , 1 : Accion realizada

  let idUsuario = sessionStorage.getItem("userid");
  let token = sessionStorage.getItem("accessToken");
  let idMesa = sessionStorage.getItem("currentMesa");

  if (iAccion === 1) {
    sessionStorage.removeItem("currentOper");
    sessionStorage.removeItem("currentMesa");
    sessionStorage.removeItem("nombreMesa");
    sessionStorage.removeItem("currentPed");

    window.location.href = "/Mesas";
  } else {
    setMesaStatus(idUsuario, token, idMesa, 999, "", "").then(
      (result) => {
        sessionStorage.removeItem("currentOper");
        sessionStorage.removeItem("currentMesa");
        sessionStorage.removeItem("nombreMesa");
        sessionStorage.removeItem("currentPed");

        window.location.href = "/Mesas";
      },

      (error) => {
        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );
  }
}

function logout() {
  sessionStorage.clear();
  window.location.href = "/";
}

async function getPedidos(idUsuario, token, idMesa, idPedido) {
  return fetch(configData.getPedidos, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({ idMesa: idMesa, idPedido: idPedido }),
  }).then((data) => data.json());
}

async function delPedido(idUsuario, token, idPedido) {
  const data = await fetch(configData.delPedido, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({ idPedido: idPedido }),
  });
  return await data.json();
}

async function solicitarCuenta(idUsuario, token, idMesa) {
  const data = await fetch(configData.solicitarCuenta, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({ idMesa: idMesa, idUsuario: idUsuario }),
  });
  return await data.json();
}

async function setMesaStatus(
  idUsuario,
  token,
  idMesa,
  inStatus,
  inMesasId,
  inMesasNum
) {
  const data = await fetch(configData.setMesaStatus, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({
      idMesa: idMesa,
      inStatus: inStatus,
      mesasId: inMesasId,
      mesasNum: inMesasNum,
    }),
  });
  return await data.json();
}

export default function MenuMesas(props) {
  const [pedidosArray, setPedidosArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [severity, setSeverity] = React.useState();
  const [msg, setMsg] = React.useState();
  const [showPedidos, setShowPedidos] = React.useState(false);

  const [showSplitCuentasT, setShowSplitCuentasT] = React.useState(false);
  const [showSplitCuentasI, setShowSplitCuentasI] = React.useState(false);

  const timer = React.useRef();

  const usuario = sessionStorage.getItem("nombre");
  const currentMesa = sessionStorage.getItem("currentMesa");
  const nombreMesa = sessionStorage.getItem("nombreMesa");

  //---

  const getBill = () => {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    if (!show) {
      setSeverity("info");
      setMsg("Solicitando cuenta...");

      setShow(true);
      setLoading(true);
      setDialog(true);

      solicitarCuenta(idUsuario, token, currentMesa).then(
        (result) => {
          if ("error" in result) logout();

          timer.current = window.setTimeout(() => {
            setLoading(false);
            setShow(false);
            setDialog(false);

            if (result.codigo === 0)
              Swal.fire({
                title: result.message,
                text: "RETIRAR CUENTA EN IMPRESORA...",
                icon: "success",
              }).then((result) => {
                backToMesas(1);
              });
            else if (result.codigo === 1)
              Swal.fire({
                title: "SIN CUENTA",
                text: result.message,
                icon: "info",
              });

            sessionStorage.removeItem("currentOper");
          }, 2000);
        },

        (error) => {
          setLoading(false);
          setDialog(false);
          setShow(false);

          Swal.fire({
            title: "ERROR",
            text: error,
            icon: "error",
          });

          sessionStorage.removeItem("currentOper");
          sessionStorage.removeItem("currentPed");
        }
      );
    }
  };

  const showPedidosMesa = async (e) => {
    setIsLoading(true);

    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    const response = await getPedidos(idUsuario, token, currentMesa, 0);

    

    timer.current = window.setTimeout(() => {
      

      if ("error" in response) logout();

      setIsLoading(false);

      if (response.error === 2) logout();
      else {
        setPedidosArray(response);
        if (response.length > 0) setShowPedidos(true);
        else
          Swal.fire({
            title: "No hay Pedidos",
            text: "No existen pedidos realizados...",
            icon: "info",
          });
      }
    }, 2000);
  };

  const splitCuentaT = async (e) => {
    setIsLoading(true);

    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    const response = await getPedidos(idUsuario, token, currentMesa, 0);

    timer.current = window.setTimeout(() => {
      setIsLoading(false);
      setPedidosArray(response);

      if (response.length > 0) setShowSplitCuentasT(true);
      else
        Swal.fire({
          title: "No hay Pedidos",
          text: "No existen pedidos realizados...",
          icon: "info",
        });
    }, 2000);
  };

  const splitCuentaI = async (e) => {
    setIsLoading(true);

    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    const response = await getPedidos(idUsuario, token, currentMesa, 0);

    timer.current = window.setTimeout(() => {
      setIsLoading(false);
      setPedidosArray(response);

      if (response.length > 0) setShowSplitCuentasI(true);
      else
        Swal.fire({
          title: "No hay Pedidos",
          text: "No existen pedidos realizados...",
          icon: "info",
        });
    }, 2000);
  };

  function operacionMesa(props) {
    if (props === 0) {
      sessionStorage.setItem("currentOper", "crearpedido");
      window.location.href = "/Pedido";
    } else if (props === 1) {
      /*Swal.fire({
        customClass: { container: "App.css" },
        showCloseButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: "rgba(0,0,123,0.4)",
        title: "VARIAS CUENTAS",
        text: "¿QUIERES DIVIDIR LA CUENTA?",
        showDenyButton: true,
        confirmButtonText: "SI",
        denyButtonText: "NO",
        icon: "question",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            customClass: { container: "App.css" },
            showCloseButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            backdrop: "rgba(0,0,123,0.4)",
            title: "DIVIDIR CUENTA",
            text: "¿PARTES IGUALES?",
            showDenyButton: true,
            confirmButtonText: "SI",
            denyButtonText: "NO",
            icon: "question",
          }).then((result) => {
            if (result.isConfirmed) {
              sessionStorage.setItem("currentOper", "verpedidos");
              showPedidosMesa();
            } else {
              sessionStorage.setItem("currentOper", "verpedidos");
              showPedidosMesa();
            }
          });
        } else {*/
      sessionStorage.setItem("currentOper", "pedircuenta");
      getBill();
      // }
      //});
    } else if (props === 2) {
      sessionStorage.setItem("currentOper", "verpedidos");
      showPedidosMesa();
    }
  }

  const handleBeforeUnload = (event) => {
    let idMesa = sessionStorage.getItem("currentMesa");
    let opera = sessionStorage.getItem("currentOper");
    let idPedido = sessionStorage.getItem("currentPed");

    if (
      (idMesa === "" || idMesa === "" || idMesa === null) &&
      opera !== "crearpedido"
    ) {
      if (
        (idPedido === "" || idPedido === "" || idPedido === null) &&
        opera !== "editarpedido"
      ) {
        let idUsuario = sessionStorage.getItem("userid");
        let token = sessionStorage.getItem("accessToken");

        setMesaStatus(idUsuario, token, idMesa, 999, "", "");

        sessionStorage.removeItem("currentOper");
        sessionStorage.removeItem("currentMesa");
        sessionStorage.removeItem("nombreMesa");
        sessionStorage.removeItem("currentPed");
      }
    }
  };

  const editPedidoPrev = (idPedido) => {
    Swal.fire({
      customClass: { container: "App.css" },
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: "rgba(0,0,123,0.4)",
      title: "PEDIDO EN LA COCINA",
      text: "¿QUIERES ELIMINAR O EDITAR EL PEDIDO?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "CAMBIAR",
      denyButtonText: "ELIMINAR",
      cancelButtonText: "CANCELAR",
      icon: "question",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.setItem("currentOper", "editarpedido");
        sessionStorage.setItem("currentPed", idPedido);
        window.location.href = "/EditaPedido";
      } else if (result.isDenied) {
        setIsLoading(true);

        let idUsuario = sessionStorage.getItem("userid");
        let token = sessionStorage.getItem("accessToken");

        delPedido(idUsuario, token, idPedido).then(
          (result) => {
            if ("error" in result) logout();

            timer.current = window.setTimeout(() => {
              setIsLoading(false);

              if (result.codigo === 0)
                Swal.fire({
                  title: "LISTO!",
                  text: "EL PEDIDO SE HA ELIMINADO...",
                  icon: "success",
                }).then((result) => {
                  setShowPedidos(false);
                  showPedidosMesa();
                });
              else if (result.codigo === 1)
                Swal.fire({
                  title: "ERROR",
                  text: "NO SE PUDO ELIMINAR EL PEDIDO",
                  icon: "error",
                });
            }, 2000);
          },

          (error) => {
            setIsLoading(false);

            Swal.fire({
              title: "ERROR",
              text: error,
              icon: "error",
            });
          }
        );
      }
    });
  };

  React.useEffect(() => {
    let idMesa = sessionStorage.getItem("currentMesa");

    if (idMesa === "" || idMesa === "" || idMesa === null) {
      window.location.href = "/Mesas";
    }

    window.addEventListener("beforeunload", handleBeforeUnload, {
      capture: true,
    });
    return () =>
      window.removeEventListener("beforeunload", handleBeforeUnload, {
        capture: true,
      });
  }, []);

  return (
    <React.Fragment>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: 100000000 }} open={isLoading}>
          {isLoading && <CircularProgress color="inherit" />}
        </Backdrop>
      </div>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar color="primary" style={{ height: "70px", maxHeight: "100px" }}>
          <Toolbar style={{ height: "70px", maxHeight: "100px" }}>
            <Grid
              sx={{ mt: 3 }}
              container
              justifyContent="flex-start"
              width="200px"
            >
              <Avatar
                sx={{ width: 32, height: 32, mr: 1 }}
                alt={usuario}
                src="/static/images/avatar/1.jpg"
              />
              <Chip
                sx={{ color: "white" }}
                label={usuario}
                variant="filled"
                size="medium"
              />
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ mt: 0.5, flexGrow: 1, width: 200 }}
              >
                Qué quieres hacer?
              </Typography>
            </Grid>
            <Grid sx={{ mt: 2 }} container justifyContent="flex-end">
              <Button
                size="small"
                onClick={() => backToMesas(0)}
                color="error"
                variant="contained"
              >
                Cancelar
              </Button>
            </Grid>
          </Toolbar>
          <Box
            sx={{
              p: 1,
              flexGrow: 1,
              backgroundColor: "white",
              mt: 2,
              display: "flex",
              "& > *": {
                m: 1,
              },
            }}
            component="span"
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ maxHeight: 55 }}
          >
            <Chip
              size="medium"
              label={nombreMesa}
              variant="filled"
              color="info"
              width="100%"
            />
          </Box>
        </AppBar>
      </ElevationScroll>

      <Box
        sx={{
          mt: 15,
        }}
      >
        {images.map((image) => (
          <ImageButton
            focusRipple
            key={image.title}
            onClick={() => operacionMesa(image.id)}
            style={{
              width: image.width,
            }}
          >
            <ImageSrc
              style={{ backgroundImage: `url(${image.url})`, opacity: 0.7 }}
            />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
              <Typography
                component="span"
                variant="h5"
                color="inherit"
                sx={{
                  position: "relative",
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                {image.title}
                <ImageMarked className="MuiImageMarked-root" />
              </Typography>
            </Image>
          </ImageButton>
        ))}
      </Box>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={show}
        >
          {loading && <CircularProgress color="inherit" />}
        </Backdrop>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={dialog}
            autoHideDuration={6000}
          >
            <Alert severity={severity}>{msg}</Alert>
          </Snackbar>
        </Stack>
      </div>
      <div>
        <Modal
          keepMounted
          open={showPedidos}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              flexGrow: 1,
              overflow: "auto",
              backgroundColor: "white",
              height: 1000,
            }}
          >
            <Stack spacing={2}>
              <ItemHeader>PEDIDOS REALIZADOS</ItemHeader>
            </Stack>

            {pedidosArray.map((pedido) =>
              pedido.estado === 1 ? (
                <Stack spacing={1} key={pedido.producto + pedido.id_pedido}>
                  <ItemPedido>
                    
                      {pedido.agregado === null
                        ? pedido.producto + " : "
                        : pedido.producto + " (" + pedido.agregado + ") : "}
                      {pedido.cantidad}
                    
                    <IconButton
                      sx={{ boxShadow: 10, color: "#105aa3", ml: 2 }}
                      size="small"
                      aria-label="edit-item"
                      color="inherit"
                      onClick={() => {
                        editPedidoPrev(pedido.id_pedido);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </ItemPedido>
                </Stack>
              ) : (
                <Stack spacing={1} key={pedido.producto + pedido.id_pedido}>
                  <ItemPedido>
                    <b>
                      <strike>
                        {pedido.agregado === null
                          ? pedido.producto + " : "
                          : pedido.producto + " (" + pedido.agregado + ") : "}
                        {pedido.cantidad}
                      </strike>
                    </b>
                  </ItemPedido>
                </Stack>
              )
            )}

            <Box
              sx={{
                alignSelf: "flex-end",
                position: "fixed",
                bottom: "1%",
                right: "1%",
                flexDirection: "column",
                flexGrow: 1,
                display: "flex",
              }}
            >
              <Fab
                style={{
                  marginTop: 5,
                  color: "white",
                  backgroundColor: "#40e3d0",
                }}
                aria-label="volver a pedidos"
                onClick={() => {
                  sessionStorage.removeItem("currentOper");
                  setShowPedidos(false);
                }}
              >
                <ClearIcon />
              </Fab>
            </Box>
          </Box>
        </Modal>
      </div>
    </React.Fragment>
  );
}
