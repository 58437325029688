import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import imgHome from "./imgs/tinajas_home.jpeg";
import configData from "./config.json";
import imgMeseros from "./imgs/meseros.png";

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${imgHome})`,
    backgroundSize: "cover",
  },
  paper: {
    margin: useTheme().spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: useTheme().spacing(1),
    backgroundColor: "red !important",
  },
  form: {
    width: "100%",
    marginTop: useTheme().spacing(1),
  },
  submit: {
    margin: useTheme().spacing(3, 0, 2),
  },
}));

async function loginUser(credentials) {
  return fetch(configData.login, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function Signin() {
  const classes = useStyles();
  const [user, setUserName] = useState("");
  const [pass, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    if (user !== "" && pass !== "") {
      setIsLoading(true);

      e.preventDefault();

      const response = await loginUser({
        user,
        pass,
      });

      if ("current_token" in response) {
        setIsLoading(false);

        Swal.fire({
          title: "Ingreso Correcto",
          text: response.message,
          icon: "success",
        }).then((value) => {
          sessionStorage.setItem("accessToken", response.current_token);
          sessionStorage.setItem("userid", response.id);
          sessionStorage.setItem("usertype", response.tipo);
          sessionStorage.setItem("nombre", response.nombre);
          sessionStorage.setItem("username", response.username);

          window.location.href = "/Mesas";
        });
      } else {
        setIsLoading(false);
        Swal.fire({
          title: "ERROR",
          text: response.message,
          icon: "error",
        });
      }
    }
  };

  return (
    <Grid container className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image} />
      <Grid item xs={12} md={5} component={Paper} elevation={6} square>
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            {isLoading && <CircularProgress color="inherit" />}
          </Backdrop>
        </div>
        <div className={classes.paper}>

          <img style={{height: "15vh", marginBottom: 5}} src={imgMeseros} ></img>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Ingresa tus credenciales
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              autoComplete="off"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="user"
              name="user"
              label="Usuario"
              onChange={(e) => setUserName(e.target.value)}
            />
            <TextField
              autoComplete="off"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="pass"
              name="pass"
              label="Contraseña"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              entrar
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
