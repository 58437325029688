import React from "react";
import "./css/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from "./Signin";
import Mesas from "./Mesas";
import MenuMesa from "./MenuMesa";
import Pedido from "./Pedido";
import EditaPedido from "./EditaPedido";

function App() {
  const token = sessionStorage.getItem("accessToken");

  if (!token) {
    sessionStorage.clear();
    return <Signin />;
  } 

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route exact path="/Mesas" element={<Mesas />}></Route>
          <Route exact path="/" element={<Mesas />}></Route>
          <Route exact path="/MenuMesa" element={<MenuMesa />}></Route>
          <Route exact path="/Pedido" element={<Pedido />}></Route>
          <Route exact path="/EditaPedido" element={<EditaPedido />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
