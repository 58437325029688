import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import configData from "./config.json";
import TableBarIcon from "@mui/icons-material/TableBar";
import IconButton from "@mui/material/IconButton";
import MuiAlert from "@mui/material/Alert";
import Fab from "@mui/material/Fab";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AddTaskIcon from "@mui/icons-material/AddTask";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const ItemPedido = styled(Paper)(({ theme }) => ({
  ...theme.typography.h7,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
  ...theme.typography.h5,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  backgroundColor: "#18544d",
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function logout() {
  sessionStorage.clear();
  window.location.href = "/";
}

function toNumber(value) {
  if (isNaN(parseInt(value, 10))) {
    return -1;
  } else return parseInt(value, 10);
}

async function getMesas(idUsuario, token) {
  const data = await fetch(configData.getMesas, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
  });
  return await data.json();
}

async function setMesaStatus(
  idUsuario,
  token,
  idMesa,
  inStatus,
  inMesasId,
  inMesasNum
) {
  const data = await fetch(configData.setMesaStatus, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({
      idMesa: idMesa,
      inStatus: inStatus,
      mesasId: inMesasId,
      mesasNum: inMesasNum,
    }),
  });
  return await data.json();
}

export default function Mesas(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [mesas, setMesas] = useState([]);
  const [showMultiMesa, setShowMultiMesa] = React.useState(false);
  const [mesasUnidas, setMesasUnidas] = React.useState([]);
  const [strMesasUnidasId, setStrMesasUnidasId] = React.useState("");
  const [strMesasUnidasNum, setStrMesasUnidasNum] = React.useState("");
  const [isTakenMesas, setIsTakenMesas] = React.useState(false);

  const usuario = sessionStorage.getItem("nombre");

  const timer = React.useRef();

  function checkMesasTomadas() {
    if (!isTakenMesas) {

      let idUsuario = sessionStorage.getItem("userid");

      mesas.map((mesa) => {
        if (
          mesa.estado === 1 &&
          toNumber(mesa.last_user) === toNumber(idUsuario)
        ) {
          setIsTakenMesas(true);
          Swal.fire({
            customClass: { container: "App.css" },
            showCloseButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            backdrop: "rgba(0,0,123,0.4)",
            title: "TIENES UNA MESA TOMADA",
            text: "¿QUE QUIERES HACER?",
            showDenyButton: true,
            confirmButtonText: "UTILIZARLA",
            denyButtonText: "LIBERARLA",
            icon: "question",
          }).then((result) => {
            if (result.isConfirmed) {
              sessionStorage.setItem("currentMesa", mesa.id);
              sessionStorage.setItem("nombreMesa", mesa.nombre_mesa);
              window.location.href = "/MenuMesa";
              setIsTakenMesas(false);
            } else if (result.isDenied) {
              liberaMesa(mesa.id);
            }
          });
        }
      });
    }
  }

  function getMesaEstado(estado) {
    if (estado === 0) return "#168709";
    else if (estado === 1) return "#b9c722";
    else if (estado === 2) return "#bd7415";
    else if (estado === 3) return "#b31414";
  }

  function uneQuitaMesa(idMesa, idx) {
    var mesasAux = "";
    var mesasAuxId = "";
    var indexMesa = -1;

    //buscar indice por id
    mesasUnidas.map((mesa, index) => {
      if (mesa.id === idMesa) {
        indexMesa = index;
      }
    });

    if (indexMesa === -1) {
      mesasUnidas.push(mesas[idx]);
    } else {
      mesasUnidas.splice(indexMesa, 1);
    }

    mesasUnidas.map((mesa) => {
      mesasAux = mesasAux + mesa.numero_mesa + ",";
      mesasAuxId = mesasAuxId + mesa.id + ",";
    });

    mesasAux = mesasAux + "-";
    mesasAuxId = mesasAuxId + "-";

    setStrMesasUnidasId(mesasAuxId.replace(",-", ""));
    setStrMesasUnidasNum(mesasAux.replace(",-", ""));
  }

  function openMesasUnidas() {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    setMesaStatus(
      idUsuario,
      token,
      0,
      1,
      strMesasUnidasId,
      strMesasUnidasNum
    ).then(
      (result) => {
        sessionStorage.setItem("currentMesa", strMesasUnidasId);
        sessionStorage.setItem("nombreMesa", strMesasUnidasNum);
        sessionStorage.setItem("mesasUnidas", 1);
        window.location.href = "/MenuMesa";
      },

      (error) => {
        setIsLoading(false);

        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );
  }

  function liberaMesa(idMesa) {
    setIsLoading(true);

    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    setMesaStatus(idUsuario, token, idMesa, 999, "", "");

    timer.current = window.setTimeout(() => {
      sessionStorage.removeItem("currentMesa");
      sessionStorage.removeItem("nombreMesa");
      window.location.href = "/Mesas";
      setIsLoading(false);
      setIsTakenMesas(false);
    }, 2000);
  }

  function openMesa(idMesa, nombreMesa, estado) {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    if (estado === 0 || estado === 2) {
      setMesaStatus(idUsuario, token, idMesa, 1, "", "").then(
        (result) => {
          sessionStorage.setItem("currentMesa", idMesa);
          sessionStorage.setItem("nombreMesa", nombreMesa);
          window.location.href = "/MenuMesa";
        },

        (error) => {
          setIsLoading(false);

          Swal.fire({
            title: "ERROR",
            text: error,
            icon: "error",
          });
        }
      );
    } else {
      if (estado === 1) {
        Swal.fire({
          title: "Mesa en atención",
          text: "Mesa esta siendo atendida por otr@ meser@...",
          icon: "warning",
        });
      }
      if (estado === 3) {
        Swal.fire({
          title: "Mesa no cerrada",
          text: "No se puede hacer pedido en mesa pendiente de cerrar...",
          icon: "warning",
        });
      }

      return null;
    }
  }

  function reloadPage() {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    getMesas(idUsuario, token).then(
      (result) => {
        if ("error" in result) logout();
        setMesas(result);
      },

      (error) => {
        console.log("error al rellamar mesas");
      }
    );

    timer.current = window.setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }

  useEffect(() => {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    sessionStorage.removeItem("currentMesa");

    const intervalId = setInterval(() => {
      reloadPage();
    }, 10000);

    getMesas(idUsuario, token).then(
      (result) => {
        if ("error" in result) logout();
        setMesas(result);
      },

      (error) => {
        setIsLoading(false);

        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );

    timer.current = window.setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          {isLoading && <CircularProgress color="inherit" />}
        </Backdrop>
      </div>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar color="primary" style={{ height: "90px", maxHeight: "100px" }}>
          <Toolbar style={{ height: "90px", maxHeight: "100px" }}>
            <Grid
              sx={{ mt: 1 }}
              container
              justifyContent="flex-start"
              width="250px"
            >
              <Avatar
                sx={{ width: 32, height: 32, mr: 1 }}
                alt={usuario}
                src="/static/images/avatar/1.jpg"
              />
              <Chip
                sx={{ color: "white" }}
                label={usuario}
                variant="filled"
                size="medium"
              />
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ mt: 1, flexGrow: 1, width: 400 }}
              >
                Hola, que mesa atenderás?
                {/*<IconButton
                  sx={{
                    boxShadow: 20,
                    ml: 1,
                    color: "#cfdbe6",
                    bgcolor: "#5483a8",
                  }}
                  size="small"
                  aria-label="edit-item"
                  color="inherit"
                  onClick={() => {
                    setMesasUnidas([]);
                    setStrMesasUnidasId("");
                    setStrMesasUnidasNum("");
                    setShowMultiMesa(true);
                  }}
                >
                  <TableBarIcon />
                </IconButton>*/}
              </Typography>
            </Grid>
            <Grid sx={{ mt: 1 }} container justifyContent="flex-end">
              <Button
                size="small"
                onClick={() => logout()}
                color="error"
                variant="contained"
              >
                SALIR
              </Button>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>

      <Container>
        <Box
          sx={{
            p: 1,
            flexGrow: 1,
            mt: 11,
            display: "flex",
            "& > *": {
              m: 1,
            },
          }}
          key="box-space"
          component="span"
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          //style={{ minHeight: "30px" }}
        >
          <Grid container spacing={{ xs: 1, sm: 1 }} columns={{ xs: 2, sm: 2 }}>
            {checkMesasTomadas()}
            {mesas.map(
              (mesa) =>
                mesa.estado !== 1 && (
                  <Grid item xs={1} sm={1} key={mesa.id}>
                    <Item>
                      <Button
                        sx={{
                          bgcolor: getMesaEstado(mesa.estado),

                          ":hover": {
                            backgroundColor: "#999797", // theme.palette.primary.main
                            color: "white",
                          },
                        }}
                        size="large"
                        onClick={() =>
                          openMesa(mesa.id, mesa.nombre_mesa, mesa.estado)
                        }
                        id={mesa.id}
                        key={mesa.id}
                        variant="contained"
                      >
                        {mesa.nombre_mesa}
                      </Button>
                    </Item>
                  </Grid>
                )
            )}
          </Grid>
        </Box>
      </Container>
      <div>
        <Dialog
          fullScreen
          open={showMultiMesa}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "fixed", backgroundColor: "#63b899" }}>
            <Toolbar>
              <Stack>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h5"
                  component="div"
                >
                  ELIJE LAS MESAS A UNIR
                </Typography>

                <Typography
                  sx={{ flex: 1, color: "black" }}
                  variant="subtitle2"
                  component="div"
                >
                  MESAS: {strMesasUnidasNum}
                </Typography>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container>
            <Box
              sx={{
                p: 1,
                flexGrow: 1,
                mt: 5,
                display: "flex",
                "& > *": {
                  m: 1,
                },
              }}
              key="box-space"
              component="span"
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
              //style={{ minHeight: "30px" }}
            >
              <Grid
                container
                spacing={{ xs: 1, sm: 1 }}
                columns={{ xs: 2, sm: 2 }}
              >
                {mesas.map(
                  (mesa, idx) =>
                    mesa.estado === 0 && (
                      <Grid item xs={1} sm={1} key={mesa.id}>
                        <Item>
                          <Button
                            sx={{
                              bgcolor: getMesaEstado(mesa.estado),

                              ":hover": {
                                backgroundColor: "#999797", // theme.palette.primary.main
                                color: "white",
                              },
                            }}
                            size="large"
                            onClick={() => uneQuitaMesa(mesa.id, idx)}
                            id={mesa.id}
                            key={mesa.id}
                            variant="contained"
                          >
                            {mesa.nombre_mesa}
                          </Button>
                        </Item>
                      </Grid>
                    )
                )}
              </Grid>
            </Box>
          </Container>
          <Box
            sx={{
              alignSelf: "flex-end",
              position: "fixed",
              bottom: "1%",
              right: "1%",
              flexDirection: "column",
              flexGrow: 1,
              display: "flex",
            }}
          >
            <Fab
              style={{
                color: "white",
                backgroundColor: "#1c70c9",
              }}
              aria-label="hacer pedido"
              onClick={() => {
                openMesasUnidas();
              }}
            >
              <AddTaskIcon fontSize="medium" />
            </Fab>

            <Fab
              style={{
                marginTop: 5,
                color: "white",
                backgroundColor: "#eb5834",
              }}
              aria-label="volver a productos"
              onClick={() => {
                setMesasUnidas([]);
                setStrMesasUnidasNum("");
                setStrMesasUnidasId("");
                setShowMultiMesa(false);
              }}
            >
              <KeyboardReturnIcon fontSize="large" />
            </Fab>
          </Box>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
