import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import Fab from "@mui/material/Fab";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import SendIcon from "@mui/icons-material/Send";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import EditIcon from "@mui/icons-material/Edit";
import configData from "./config.json";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ItemPedido = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
  ...theme.typography.h5,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  backgroundColor: "#18544d",
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function backToMenuMesa() {
  sessionStorage.removeItem("currentOper");
  window.location.href = "/MenuMesa";
}

function backToMesas() {
  sessionStorage.removeItem("currentOper");
  sessionStorage.removeItem("currentMesa");
  sessionStorage.removeItem("nombreMesa");

  window.location.href = "/Mesas";
}

function logout() {
  sessionStorage.clear();
  window.location.href = "/";
}

async function getPlatos(idUsuario, token) {
  const data = await fetch(configData.getComestibles, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
  });
  return await data.json();
}

async function getLiquidos(idUsuario, token) {
  const data = await fetch(configData.getLiquidos, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
  });
  return await data.json();
}

async function getAgregadosExtra(idUsuario, token) {
  const data = await fetch(configData.getAgregadosExtra, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
  });
  return await data.json();
}

async function enviaCocina(idUsuario, token, idMesa, pedidosBody) {
  const data = await fetch(configData.addPedido, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({
      idMesa: idMesa,
      idUsuario: idUsuario,
      pedidos: pedidosBody,
    }),
  });
  return await data.json();
}

async function setMesaStatus(idUsuario, token, idMesa, inStatus, inMesasId, inMesasNum,) {
  const data = await fetch(configData.setMesaStatus, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({ idMesa: idMesa, inStatus: inStatus, mesasId: inMesasId, mesasNum: inMesasNum }),
  });
  return await data.json();
}

export default function Pedido(props) {
 
  const usuario = sessionStorage.getItem("nombre");
  const currentMesa = sessionStorage.getItem("currentMesa");
  const nombreMesa = sessionStorage.getItem("nombreMesa");

  const timer = React.useRef();
  const [value, setValue] = React.useState(0);
  const [showLoadCocina, setShowLoadCocina] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [msgCocina, setMsgCocina] = React.useState(false);
  const [resultCocina, setResultCocina] = React.useState();
  const [msgResultCocina, setMsgResultCocina] = React.useState();

  const [platos, setPlatos] = React.useState([]);
  const [liquidos, setLiquidos] = React.useState([]);
  const [agregadosExtras, setAgregadosExtras] = React.useState([]);

  const [showDivFood, setShowDivFood] = React.useState(true);

  const [openAddPedido, setOpenAddPedido] = React.useState(false);
  const [openResumen, setOpenResumen] = React.useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [openDialog, setOpenDialog] = React.useState(false);

  const [titleDialog, setTitleDialog] = React.useState("");

  const [hasAgregadoPlato, setHasAgregadoPlato] = React.useState(false);
  const [hasAgregadoLiquido, setHasAgregadoLiquido] = React.useState(false);

  const [tmpCantidadPedido, setTmpCantidadPedido] = React.useState(0);
  const [tmpAgregadoPlato, setTmpAgregadoPlato] = React.useState(0);
  const [tmpAgregadoLiquido, setTmpAgregadoLiquido] = React.useState(0);
  const [tmpAgregadoComentario, setTmpAgregadoComentario] = React.useState("");
  const [tmpCurrentPrecio, setTmpCurrentPrecio] = React.useState(0);
  const [tmpCurrentPedido, setTmpCurrentPedido] = React.useState(0);
  const [tmpCurrentCategoria, setTmpCurrentCategoria] = React.useState(-1);
  const [editItemIndex, setEditItemIndex] = React.useState(-1);

  const [listaPedidosArray, setListaPedidosArray] = React.useState([]);

  function TituloComida(props) {
    const { value, index } = props;

    var show = "none";
    var title = ".";

    if (index === 0) {
      show = "flex";
      if (value === 0) {
        title = platos[index].tipo_producto;
      } else {
        title = liquidos[index].tipo_producto;
      }
    }
    if (index > 0) {
      if (value === 0) {
        if (platos[index - 1].tipo_producto !== platos[index].tipo_producto) {
          show = "flex";
          title = platos[index].tipo_producto;
        }
      } else if (value === 1) {
        if (
          liquidos[index - 1].tipo_producto !== liquidos[index].tipo_producto
        ) {
          show = "flex";
          title = liquidos[index].tipo_producto;
        }
      }
    }

    return (
      <div>
        <Chip
          sx={{
            mt: 1,
            display: show,
            minWidth: "100%",
          }}
          size="small"
          label={title}
          variant="filled"
          color="info"
        />
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setShowDivFood(true);
    } else if (newValue === 1) {
      setShowDivFood(false);
    }
  };

  const backToPedidos = () => setOpenResumen(false);

  const procedeCocina = () => {
    if (listaPedidosArray !== null && listaPedidosArray.length > 0) {
      setShowLoadCocina(true);
      setLoading(true);

      setResultCocina("info");
      setMsgResultCocina("Enviando a la cocina...");
      setMsgCocina(true);

      //header
      let idUsuario = sessionStorage.getItem("userid");
      let token = sessionStorage.getItem("accessToken");

      //armar body
      var pedidosArray = [];

      listaPedidosArray.map((pedidos) => {
       
          let agregadoid = 0;
          (pedidos.idCategoria === 0)
          ? agregadoid = pedidos.agregadoPlato
          : agregadoid = pedidos.agregadoLiquido

          pedidosArray.push({
            idComestible: pedidos.idComestible,
            cantidad: pedidos.cantidad,
            precio: pedidos.precio,
            comentario: pedidos.comentario ,
            idAgregado: agregadoid,
          });

      });

      //llamar api cocina
      enviaCocina(idUsuario, token, currentMesa, pedidosArray).then(
        (result) => {
          if ("error" in result) logout();

          timer.current = window.setTimeout(() => {
            setMsgCocina(false);
            setLoading(false);
            setShowLoadCocina(false);

            if (result.codigo === 0) {
              Swal.fire({
                title: "Pedido Enviado",
                text: "Se envió el pedido a la cocina...",
                icon: "success",
              }).then((result) => {
                backToMesas();
              });
            } else
              Swal.fire({
                title: "ERROR",
                text: result.message,
                icon: "error",
              });

            //setMsgCocina(true);
          }, 2000);
        },

        (error) => {
          setMsgCocina(false);
          setLoading(false);
          setShowLoadCocina(false);

          Swal.fire({
            title: "ERROR",
            text: error,
            icon: "error",
          });
        }
      );

      //setOpenResumen(false);
    } else {
      Swal.fire({
        title: "Sin Productos",
        text: "No has ingresado productos para enviar a la cocina...",
        icon: "warning",
      });
    }
  };

  const borraPedidos = () => {
    if (listaPedidosArray !== null && listaPedidosArray.length > 0) {
      setIsLoading(true);

      setListaPedidosArray([]);

      timer.current = window.setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const openPedido = (id, cat, addOrEdit, idx) => {

    if (addOrEdit === 0) {
      setEditItemIndex(-1);
      setTmpCurrentPedido(id);
      setTmpCurrentCategoria(cat);

      setTmpCurrentPrecio(0);

      setTitleDialog("");
      setTmpCantidadPedido(0);

      setTmpAgregadoComentario("");

      setTmpAgregadoPlato(0);
      setTmpAgregadoLiquido(0);

      setHasAgregadoPlato(false);
      setHasAgregadoLiquido(false);
    } else if (addOrEdit === 1) {
      setEditItemIndex(idx);

      setTmpCurrentPedido(listaPedidosArray[idx].idComestible);
      setTmpCurrentCategoria(listaPedidosArray[idx].idCategoria);

      setTmpCurrentPrecio(listaPedidosArray[idx].precio);

      setTitleDialog(listaPedidosArray[idx].nombreEnCarta);
      setTmpCantidadPedido(listaPedidosArray[idx].cantidad);

      setTmpAgregadoComentario(listaPedidosArray[idx].comentario);

      setTmpAgregadoPlato(listaPedidosArray[idx].agregadoPlato);
      setTmpAgregadoLiquido(listaPedidosArray[idx].agregadoLiquido);

      setHasAgregadoPlato(false);
      setHasAgregadoLiquido(false);

      cat = listaPedidosArray[idx].idCategoria;
      id = listaPedidosArray[idx].idComestible;
    }

    if (cat === 0) {
      platos.map((plato) => {
        if (plato.id === id) {
          setTmpCurrentPrecio(plato.precio_venta);
          setTitleDialog(plato.nombre_en_carta);
          plato.agregado_gratis === 1
            ? setHasAgregadoPlato(true)
            : setHasAgregadoPlato(false);
        }
      });
    }

    if (cat === 1) {
      liquidos.map((liquido) => {
        if (liquido.id === id) {
          setTmpCurrentPrecio(liquido.precio_venta);
          setTitleDialog(liquido.nombre_en_carta);
          liquido.agregado_liquido === 1
            ? setHasAgregadoLiquido(true)
            : setHasAgregadoLiquido(false);
        }
      });
    }

    setOpenDialog(true);
  };

  const eliminaPedido = (idx) => {
    setIsLoading(true);

    var auxArray = [];
    var newIdx = -1;

    auxArray = listaPedidosArray;
    auxArray.splice(idx, 1);

    auxArray.map((aux) => {
      newIdx = newIdx + 1;
      aux.index = newIdx;
    });

    setListaPedidosArray(auxArray);

    timer.current = window.setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const addToListPedido = () => {
    setIsLoading(true);

    var auxArray = [];

    var nombreProducto = "";
    var nombreAgregado = "";

  

    if (tmpCurrentCategoria === 0) {
      platos.map((plato) => {
        if (plato.id === tmpCurrentPedido) {
          nombreProducto = plato.nombre_en_carta;
          if (plato.agregado_gratis === 1 && tmpAgregadoPlato > 0) {
            agregadosExtras.map((agregado) => {
              if (agregado.id === parseInt(tmpAgregadoPlato)) {
                nombreAgregado = agregado.nombre_en_carta;
                nombreProducto = nombreProducto + " + " + nombreAgregado;
              }
            });
          }
        }
      });
    } else if (tmpCurrentCategoria === 1) {
      liquidos.map((liquido) => {
        if (liquido.id === tmpCurrentPedido) {
          nombreProducto = liquido.nombre_en_carta;
          if (liquido.agregado_liquido === 1 && tmpAgregadoLiquido > 0) {
            agregadosExtras.map((agregado) => {
              if (agregado.id === parseInt(tmpAgregadoLiquido)) {
                nombreAgregado = agregado.nombre_en_carta;
               
                nombreProducto = nombreProducto + " como " + nombreAgregado;
              }
            });
          }
        }
      });
    }



    //buscar si hay coincidencia exacta

    var encontrado = 0;
    var idx = -1;

    if (editItemIndex !== -1) {

        encontrado = 1;
        
        listaPedidosArray[editItemIndex].cantidad = tmpCantidadPedido;
        listaPedidosArray[editItemIndex].precio = tmpCurrentPrecio;
        listaPedidosArray[editItemIndex].comentario = tmpAgregadoComentario;
        listaPedidosArray[editItemIndex].agregadoPlato = tmpAgregadoPlato;
        listaPedidosArray[editItemIndex].agregadoLiquido = tmpAgregadoLiquido;
        listaPedidosArray[editItemIndex].nombreProducto = nombreProducto;
        listaPedidosArray[editItemIndex].nombreAgregado = nombreAgregado;


    } else {
      listaPedidosArray.map((lista, index) => {
        if (
          lista.idComestible === tmpCurrentPedido &&
          lista.agregadoPlato === tmpAgregadoPlato &&
          lista.comentario === tmpAgregadoComentario
        ) {
          idx = index;
          encontrado = 1;
          lista.cantidad = lista.cantidad + tmpCantidadPedido;
        }
      });
    }

    if (encontrado === 0) {
      auxArray = listaPedidosArray;

      idx = listaPedidosArray.length;

      auxArray.push({
        index: idx,
        idCategoria: tmpCurrentCategoria,
        idComestible: tmpCurrentPedido,
        cantidad: tmpCantidadPedido,
        precio: tmpCurrentPrecio,
        comentario: tmpAgregadoComentario,
        agregadoPlato: parseInt(tmpAgregadoPlato),
        agregadoLiquido: parseInt(tmpAgregadoLiquido),
        nombreEnCarta: titleDialog,
        nombreProducto: nombreProducto,
        nombreAgregado: nombreAgregado,
      });

      setListaPedidosArray(auxArray);
    }

    setOpenDialog(false);
    setOpenAddPedido(false);

    timer.current = window.setTimeout(() => {
      setTmpCurrentPedido(0);
      setTmpCurrentCategoria(0);

      setTmpCurrentPrecio(0);

      setTitleDialog("");
      setTmpCantidadPedido(0);

      setTmpAgregadoComentario("");

      setTmpAgregadoPlato(0);
      setTmpAgregadoLiquido(0);

      setHasAgregadoPlato(false);
      setHasAgregadoLiquido(false);
      setEditItemIndex(-1);
      setIsLoading(false);
    }, 500);
  };

  const handleBeforeUnload = (event) => {

    let opera = sessionStorage.getItem("currentOper");

    if (opera !== 'crearpedido'){
      sessionStorage.removeItem("currentOper");
      
    }else{

      let idMesa = sessionStorage.getItem("currentMesa");
      let idUsuario = sessionStorage.getItem("userid");
      let token = sessionStorage.getItem("accessToken");

      setMesaStatus(idUsuario, token, idMesa, 999, '', '');

      sessionStorage.removeItem("currentOper");
      sessionStorage.removeItem("currentMesa");
      sessionStorage.removeItem("nombreMesa");
    }
    
  };

  useEffect(() => {

    let idMesa = sessionStorage.getItem("currentMesa");
    let opera = sessionStorage.getItem("currentOper");

    if (((idMesa === '') || (idMesa === "") || (idMesa === null)) && (opera !== 'crearpedido')){
      window.location.href = "/Mesas";
    }
  
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    getPlatos(idUsuario, token).then(
      (result) => {
        if ("error" in result) logout();
        setPlatos(result);
      },

      (error) => {
        setIsLoading(false);

        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );

    getLiquidos(idUsuario, token).then(
      (result) => {
        if ("error" in result) logout();
        setLiquidos(result);
      },

      (error) => {
        setIsLoading(false);

        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );

    getAgregadosExtra(idUsuario, token).then(
      (result) => {
        if ("error" in result) logout();
        setAgregadosExtras(result);
      },

      (error) => {
        setIsLoading(false);

        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );

    timer.current = window.setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    window.addEventListener("beforeunload", handleBeforeUnload, {capture: true});
    return () => window.removeEventListener("beforeunload", handleBeforeUnload, {capture: true});

  }, []);

  return (
    <React.Fragment>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          {isLoading && <CircularProgress color="inherit" />}
        </Backdrop>
      </div>
      {!openAddPedido && (
        <div>
          <CssBaseline />
          <ElevationScroll {...props}>
            <AppBar
              color="primary"
              style={{ height: "80px", maxHeight: "100px" }}
            >
              <Toolbar style={{ height: "80px", maxHeight: "100px" }}>
                <Grid
                  sx={{ mt: 3 }}
                  container
                  justifyContent="flex-start"
                  width="230px"
                >
                  <Avatar
                    sx={{ width: 32, height: 32, mr: 1 }}
                    alt={usuario}
                    src="/static/images/avatar/1.jpg"
                  />
                  <Chip
                    sx={{ color: "white" }}
                    label={usuario}
                    variant="filled"
                    size="medium"
                  />
                  <Typography
                    style={{ marginTop: 3 }}
                    variant="subtitle2"
                    component="div"
                    sx={{ flexGrow: 1, width: 400 }}
                  >
                    Crea el pedido para la {nombreMesa}
                  </Typography>
                </Grid>
                <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                  <Button
                    size="small"
                    onClick={() => backToMenuMesa()}
                    color="error"
                    variant="contained"
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Toolbar>
              <div>
                <Chip
                  sx={{
                    mt: 3.1,
                    minWidth: "100%",
                    backgroundColor: "#4c6785",
                  }}
                  size="small"
                  label="PEDIDOS"
                  variant="filled"
                  color="info"
                />
              </div>
            </AppBar>
          </ElevationScroll>

          <Stack
            sx={{ mt: 15 }}
            spacing={1}
            //key={
            //  lista.idComestible + lista.nombreProducto + lista.nombreAgregado + lista.comentario
            // }
          >
            {listaPedidosArray.map((lista) => (
              <Item
                key={
                  lista.idComestible +
                  lista.nombreProducto +
                  lista.nombreAgregado +
                  lista.comentario
                }
              >
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  {lista.cantidad + " - " + lista.nombreProducto}
                </Typography>
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ flexGrow: 1, width: 300, fontSize:14, textAlign:'left' }}
                    >
                    {(lista.comentario !== '') ? "(" + lista.comentario + ")" : ""}
                  </Typography>
                <Stack direction="row" spacing={1} alignItems="flex-end">
                  <IconButton
                    sx={{ boxShadow: 10, color: "red" }}
                    size="small"
                    aria-label="delete-item"
                    color="inherit"
                    onClick={() => {
                      eliminaPedido(lista.index);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                  <IconButton
                    sx={{ boxShadow: 10, color: "#105aa3" }}
                    size="small"
                    aria-label="edit-item"
                    color="inherit"
                    onClick={() => {
                      openPedido(0, 0, 1, lista.index);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Stack>
              </Item>
            ))}
          </Stack>
          <Box
            sx={{
              alignSelf: "flex-end",
              position: "fixed",
              bottom: "1%",
              right: "1%",
              flexDirection: "column",
              flexGrow: 1,
              display: "flex",
            }}
          >
            <Fab
              sx={{
                ":hover": {
                  backgroundColor: "#117ced", // theme.palette.primary.main
                  color: "white",
                },

                color: "white",
                backgroundColor: "#2f70b5",
                marginTop: 1,
              }}
              aria-label="agrega producto"
              onClick={() => {
                setOpenAddPedido(true);
              }}
            >
              <AddIcon fontSize="large" />
            </Fab>
            <Fab
              sx={{
                ":hover": {
                  backgroundColor: "#135e30", // theme.palette.primary.main
                  color: "white",
                },
                marginTop: 1,
                color: "white",
                backgroundColor: "#198a44",
              }}
              aria-label="muestra pedidos"
              onClick={() => {
                procedeCocina();
              }}
            >
              <SendIcon fontSize="medium" />
            </Fab>
            <Fab
              sx={{
                ":hover": {
                  backgroundColor: "#8c321c", // theme.palette.primary.main
                  color: "white",
                },
                marginTop: 1,
                color: "white",
                backgroundColor: "#eb5834",
              }}
              aria-label="borra todo"
              onClick={() => {
                borraPedidos();
              }}
            >
              <DeleteForeverIcon fontSize="large" />
            </Fab>
          </Box>
        </div>
      )}
      {openAddPedido && (
        <div>
          <CssBaseline />
          <ElevationScroll {...props}>
            <AppBar
              color="primary"
              style={{ height: "50px", maxHeight: "100px" }}
            >
              <Box
                sx={{
                  p: 1,
                  flexGrow: 1,
                  backgroundColor: "white",
                  borderBottom: 1,
                  borderColor: "primary.main",
                  mt: 0,
                  display: "flex",
                  "& > *": {
                    m: 1,
                  },
                }}
                component="span"
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="center"
                //style={{ maxHeight: 55 }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="tabs comidas bebidas"
                  indicatorColor="secondary"
                  textColor="secondary"
                >
                  <Tab icon={<RestaurantIcon />} label="" />
                  <Tab icon={<LocalDrinkIcon />} label="" />
                </Tabs>
                <Chip
                  size="large"
                  label="ELIJE EL PRODUCTO"
                  variant="filled"
                  color="info"
                  width="100%"
                />
              </Box>
            </AppBar>
          </ElevationScroll>
          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: "white",
              height: 1000,
            }}
          >
            <Container
              sx={{
                mt: 10,
              }}
            >
              {showDivFood && (
                <div>
                  {platos.map((plato, index) => (
                    <Stack spacing={1} key={plato.id + plato.categoria}>
                      <TituloComida index={index} value={plato.categoria} />
                      <Item>
                        <Chip
                          sx={{
                            marginRight: 1,
                            boxShadow: 20,
                            width: "240px",
                            textAlign: "left",
                          }}
                          size="large"
                          label={plato.nombre_en_carta}
                          variant="outlined"
                          color={plato.stock > 0 ? "success" : "error"}
                          clickable={true}
                          onClick={() => {
                            openPedido(plato.id, plato.categoria, 0, 0);
                          }}
                        />

                        <Chip
                          size="small"
                          label={"$" + plato.precio_venta}
                          variant="filled"
                          color={plato.stock > 0 ? "success" : "error"}
                        />
                      </Item>
                    </Stack>
                  ))}
                </div>
              )}
              {!showDivFood && (
                <div>
                  {liquidos.map((liquido, index) => (
                    <Stack spacing={1} key={liquido.id + liquido.categoria}>
                      <TituloComida index={index} value={liquido.categoria} />
                      <Item>
                        <Chip
                          sx={{
                            marginRight: 1,
                            boxShadow: 20,
                            width: "240px",
                            textAlign: "left",
                          }}
                          size="large"
                          label={liquido.nombre_en_carta}
                          variant="outlined"
                          color={liquido.stock > 0 ? "success" : "error"}
                          clickable={true}
                          onClick={() => {
                            openPedido(liquido.id, liquido.categoria, 0, 0);
                            //restaPedido(liquido.id, liquido.categoria);
                          }}
                        />

                        <Chip
                          size="small"
                          label={"$" + liquido.precio_venta}
                          variant="filled"
                          color={liquido.stock > 0 ? "success" : "error"}
                        />
                      </Item>
                    </Stack>
                  ))}
                </div>
              )}
            </Container>

            <Box
              sx={{
                alignSelf: "flex-end",
                position: "fixed",
                bottom: "1%",
                right: "1%",
                flexDirection: "column",
                flexGrow: 1,
                display: "flex",
              }}
            >
              <Fab
                sx={{
                  ":hover": {
                    backgroundColor: "#8c321c", // theme.palette.primary.main
                    color: "white",
                  },
                  marginTop: 5,
                  color: "white",
                  backgroundColor: "#eb5834",
                }}
                aria-label="volver a pedidos"
                onClick={() => {
                  setOpenAddPedido(false);
                }}
              >
                <KeyboardReturnIcon fontSize="large" />
              </Fab>
            </Box>
          </Box>
        </div>
      )}
      <div>
        <Modal
          keepMounted
          open={openResumen}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              flexGrow: 1,

              backgroundColor: "white",
              height: 1000,
            }}
          >
            <Stack spacing={2}>
              <ItemHeader>RESUMEN DEL PEDIDO</ItemHeader>
            </Stack>

            {platos.map((plato) =>
              plato.pedido > 0 ? (
                <Stack spacing={1} key={plato.id + plato.categoria}>
                  <ItemPedido>
                    {plato.nombre_en_carta} : {plato.pedido}
                  </ItemPedido>
                </Stack>
              ) : null
            )}

            {liquidos.map((liquido) =>
              liquido.pedido > 0 ? (
                <Stack spacing={1} key={liquido.id + liquido.categoria}>
                  <ItemPedido>
                    {liquido.nombre_en_carta} : {liquido.pedido}
                  </ItemPedido>
                </Stack>
              ) : null
            )}

            <Box
              sx={{
                alignSelf: "flex-end",
                position: "fixed",
                bottom: "1%",
                right: "1%",
                flexDirection: "column",
                flexGrow: 1,
                display: "flex",
              }}
            >
              <Fab
                style={{
                  color: "white",
                  backgroundColor: "#198a44",
                }}
                aria-label="hacer pedido"
                onClick={() => {
                  procedeCocina();
                }}
              >
                <SendIcon fontSize="medium" />
              </Fab>

              <Fab
                style={{
                  marginTop: 5,
                  color: "white",
                  backgroundColor: "#eb5834",
                }}
                aria-label="volver a pedidos"
                onClick={() => {
                  backToPedidos();
                }}
              >
                <KeyboardReturnIcon fontSize="large" />
              </Fab>
            </Box>
          </Box>
        </Modal>
      </div>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showLoadCocina}
        >
          {loading && <CircularProgress color="inherit" />}
        </Backdrop>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={msgCocina}
            autoHideDuration={null}
          >
            <Alert severity={resultCocina}>{msgResultCocina}</Alert>
          </Snackbar>
        </Stack>
      </div>
      <div>
        <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative", backgroundColor: "#63b899" }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                {titleDialog}
              </Typography>
            </Toolbar>
          </AppBar>

          <Box sx={{ ml: 2, mt: 5, width: 320 }}>
            {hasAgregadoPlato && (
              <Item sx={{ mb: 2 }}>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Elije el agregado:
                </Typography>
                <Grid>
                  <Select
                    sx={{ width: 300 }}
                    labelId="select-agregado-gratis"
                    id="select-agregado-gratis-id"
                    value={tmpAgregadoPlato}
                    label="Agregado"
                    onChange={(event) => {
                      setTmpAgregadoPlato(event.target.value);
                    }}
                  >
                    <MenuItem key="0" value={0}>
                      Sin Agregado
                    </MenuItem>

                    {agregadosExtras.map((agregado) =>
                      agregado.categoria === 2 ? (
                        <MenuItem key={agregado.id} value={agregado.id}>
                          {agregado.nombre_en_carta}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </Grid>
              </Item>
            )}
            {hasAgregadoLiquido && (
              <Item sx={{ mb: 2 }}>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Cómo la quieres?
                </Typography>
                <Grid>
                  <FormControl>
                    <RadioGroup
                      
                      aria-labelledby="radio_button_agregado"
                      name="radio_button_agregado"
                      value={tmpAgregadoLiquido}
                      onChange={(event) => {
                        setTmpAgregadoLiquido(event.target.value);
                      }}
                    >
                      <FormControlLabel
                        key="0"
                        value={0}
                        control={<Radio />}
                        label="Solita"
                      />
                      {agregadosExtras.map((agregado) =>
                        agregado.categoria === 3 ? (
                          <FormControlLabel
                            key={agregado.id}
                            value={agregado.id}
                            control={<Radio />}
                            label={
                              agregado.nombre_en_carta +
                              " $" +
                              agregado.precio_venta
                            }
                          />
                        ) : null
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Item>
            )}
            <Item>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Cuántos quieres?
              </Typography>
              <Grid sx={{ marginTop: 1 }}>
                <IconButton
                  sx={{ boxShadow: 10, color: "#39a828" }}
                  size="medium"
                  aria-label="delete"
                  color="inherit"
                  onClick={() => {
                    setTmpCantidadPedido(tmpCantidadPedido + 1);
                  }}
                >
                  <AddOutlinedIcon />
                </IconButton>
                {
                  <Button
                    sx={{ fontSize: 20 }}
                    style={{ color: "#260502" }}
                    disabled
                    value=""
                    size="large"
                  >
                    {tmpCantidadPedido}
                  </Button>
                }
                <IconButton
                  disabled={tmpCantidadPedido <= 0}
                  sx={{ boxShadow: 10, marginRight: 2, color: "red" }}
                  size="medium"
                  aria-label="delete"
                  color="inherit"
                  onClick={() => {
                    setTmpCantidadPedido(tmpCantidadPedido - 1);
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Item>
            <Item sx={{ marginTop: 2 }}>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Alguna indicación al cocinero?
              </Typography>
              <TextField
                autoComplete='off'
                inputProps={{
                  maxLength: 60,
                }}
                sx={{ width: 300 }}
                id="txt-comment-plato"
                label=""
                variant="outlined"
                value={tmpAgregadoComentario}
                onChange={(event) => {
                  setTmpAgregadoComentario(event.target.value);
                }}
              />
            </Item>
          </Box>

          <Box
            sx={{
              alignSelf: "flex-end",
              position: "fixed",
              bottom: "1%",
              right: "1%",
              flexDirection: "column",
              flexGrow: 1,
              display: "flex",
            }}
          >
            <Fab
              style={{
                color: "white",
                backgroundColor: "#198a44",
              }}
              aria-label="hacer pedido"
              onClick={() => {
                if (tmpCantidadPedido > 0) addToListPedido();
              }}
            >
              <AddTaskIcon fontSize="medium" />
            </Fab>

            <Fab
              style={{
                marginTop: 5,
                color: "white",
                backgroundColor: "#eb5834",
              }}
              aria-label="volver a productos"
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              <KeyboardReturnIcon fontSize="large" />
            </Fab>
          </Box>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
